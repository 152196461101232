import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';

import { isOnCurrentShiftAtom, isSSEConnectedAtom } from '~/pages/Home/eCallComponents/atom';

import { queryClient, useBranchId, useToken } from '../common';

export type EventType = {
    event: string;
    data: { timestamp: string };
};

const MILLISECONDS_TO_RETRY = 10000; // 10 seconds

export const useServerSentEvents = () => {
    const token = useToken();
    const branchId = useBranchId();

    const fails = useRef(0);

    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);
    const [isSSEConnected, setIsSSEConnected] = useAtom(isSSEConnectedAtom);

    // using ref instead of state because the `fetchEventSource` methods aren't synchronized
    // with the state value, and they won't be able to use its most recent value
    const updateFails = (value: number) => {
        fails.current = value;
    };

    useEffect(() => {
        // preventing multiple SSE connections
        if (isSSEConnected) return;

        void fetchEventSource(process.env.REACT_APP_SSE_BASE_URL + 'events', {
            headers: { authorization: token },
            openWhenHidden: true, // run in the background

            // eslint-disable-next-line @typescript-eslint/require-await
            onopen: async () => {
                setIsSSEConnected(true);
                updateFails(0);
            },

            onclose: () => {
                setIsSSEConnected(false);
                updateFails(0);
            },

            onmessage: (event) => {
                const data = JSON.parse(event.data) as EventType;

                void handleECallEvents(data);
            },

            onerror: (error) => {
                if ((error as Error).message === 'network error') {
                    // skipping this one because it happens all the time.
                    // connection error is `Failed to fetch`
                    return;
                }

                // stop retrying after 3 fails
                const shouldRetry = fails.current < 2;

                if (!shouldRetry) {
                    setIsSSEConnected(false);
                    throw new Error('Failed to connect to SSE');
                }

                updateFails(fails.current + 1);
                return MILLISECONDS_TO_RETRY;
            },
        });
    }, [isSSEConnected]);

    const handleECallEvents = async (event: EventType) => {
        if (event.event === 'ecall/refresh-calls' && isOnCurrentShift) {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ['ongoing-calls', branchId] }),
                queryClient.invalidateQueries({ queryKey: ['undocumented-calls', branchId] }),
            ]);
        }
    };
};
